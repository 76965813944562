export const foxLangugeVn = [
  {
    LabelCode: "ProductName",
    LabelValue: "Tên sản phẩm",
  },
  {
    LabelCode: "C.SpecialOffers.SpecialOffer",
    LabelValue: "Ưu đãi đặc biệt",
  },
  {
    LabelCode: "C.HeaderBottom.ShopByCategory",
    LabelValue: "Danh mục sản phẩm",
  },
  {
    LabelCode: "C.BestSellers.BestSeller",
    LabelValue: "Sản phẩm bán chạy",
  },
  {
    LabelCode: "C.MultiComponent.AddToCart",
    LabelValue: "Thêm vào giỏ hàng",
  },
  {
    LabelCode: "C.Product.ViewDetail",
    LabelValue: "Xem chi tiết",
  },
  {
    LabelCode: "C.Product.AddToWishList",
    LabelValue: "Thêm vào danh sách mong muốn",
  },
  {
    LabelCode: "P.SignUp.Header",
    LabelValue: "Tạo tài khoản",
  },
  {
    LabelCode: "P.Signup.FullName",
    LabelValue: "Họ tên đầy đủ",
  },
  {
    LabelCode: "P.Signup.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.Signup.Phone",
    LabelValue: "Số điện thoại",
  },
  {
    LabelCode: "P.Signup.Password",
    LabelValue: "Mật khẩu",
  },
  {
    LabelCode: "P.Signup.Address",
    LabelValue: "Địa chỉ",
  },
  {
    LabelCode: "P.Signup.City",
    LabelValue: "Thành phố",
  },
  {
    LabelCode: "P.Signup.Country",
    LabelValue: "Quốc gia",
  },
  {
    LabelCode: "P.Signup.Zip",
    LabelValue: "Mã bưu chính",
  },
  {
    LabelCode: "P.Signup.Agree",
    LabelValue: "Đồng ý điều khoản sử dụng",
  },
  {
    LabelCode: "P.Signup.CreateAccountButton",
    LabelValue: "Tạo tài khoản",
  },
  {
    LabelCode: "P.Signup.NoAccount",
    LabelValue: "Đã có tài khoản",
  },
  {
    LabelCode: "C.HeaderBottom.SearchInput.Plh",
    LabelValue: "Tìm gì cũng có, giao nhanh như gió",
  },
  {
    LabelCode: "T.Toast.AddToCart",
    LabelValue: "Đã thêm sản phẩm vào giỏ hàng",
  },
  {
    LabelCode: "T.Toast.RemoveFromCart",
    LabelValue: "Đã xóa sản phẩm khỏi giỏ hàng",
  },

  {
    LabelCode: "P.CheckoutProcess.FullName",
    LabelValue: "Họ tên đầy đủ",
  },
  {
    LabelCode: "P.CheckoutProcess.FullName.Plh",
    LabelValue: "Họ tên đầy đủ",
  },
  {
    LabelCode: "P.CheckoutProcess.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.CheckoutProcess.Email.Plh",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.CheckoutProcess.Phone.Plh",
    LabelValue: "0999999999",
  },
  {
    LabelCode: "P.CheckoutProcess.Phone",
    LabelValue: "Số điện thoại",
  },
  {
    LabelCode: "P.CheckoutProcess.Password.Plh",
    LabelValue: "Password",
  },
  {
    LabelCode: "P.CheckoutProcess.Address.Plh",
    LabelValue: "123 Trần Hưng Đạo, Quận 5",
  },

  {
    LabelCode: "P.CheckoutProcess.Address",
    LabelValue: "Địa chỉ nhận hàng",
  },
  {
    LabelCode: "P.CheckoutProcess.City.Plh",
    LabelValue: "Hồ Chí Minh 1",
  },
  {
    LabelCode: "P.CheckoutProcess.City",
    LabelValue: "Tỉnh/Thành phố",
  },
  {
    LabelCode: "P.CheckoutProcess.Country.Plh",
    LabelValue: "Việt Nam",
  },
  {
    LabelCode: "P.CheckoutProcess.Country",
    LabelValue: "Quốc gia",
  },
  {
    LabelCode: "P.CheckoutProcess.Zip.Plh",
    LabelValue: "70949",
  },
  {
    LabelCode: "P.CheckoutProcess.Zip",
    LabelValue: "Mã bưu chính",
  },
  {
    LabelCode: "P.CheckoutProcess.Note.Plh",
    LabelValue: "Mang theo tiền lẻ",
  },
  {
    LabelCode: "P.CheckoutProcess.Note",
    LabelValue: "Ghi chú",
  },
  {
    LabelCode: "P.CheckoutProcess.Agree",
    LabelValue: "Đồng ý với",
  },
  {
    LabelCode: "P.CheckoutProcess.CheckOut",
    LabelValue: "Xác nhận",
  },
  {
    LabelCode: "P.CheckoutProcess.Header",
    LabelValue: "Thông tin giao hàng",
  },
  {
    LabelCode: "C.Multi.ProductName",
    LabelValue: "Tên sản phẩm",
  },
  {
    LabelCode: "C.Multi.Price",
    LabelValue: "Giá",
  },
  {
    LabelCode: "C.Multi.Quantity",
    LabelValue: "Số lượng",
  },
  {
    LabelCode: "C.Multi.SubTotal",
    LabelValue: "Thành tiền",
  },
  {
    LabelCode: "C.Multi.ApplyCoupon",
    LabelValue: "Áp dụng mã giảm giá",
  },
  {
    LabelCode: "C.OrderConfirm.UserInformation",
    LabelValue: "Thông tin người nhận hàng",
  },

  {
    LabelCode: "C.OrderConfirm.FullName",
    LabelValue: "Họ tên",
  },

  {
    LabelCode: "C.OrderConfirm.Email",
    LabelValue: "Email",
  },

  {
    LabelCode: "C.OrderConfirm.Phone",
    LabelValue: "Số điện thoại",
  },

  {
    LabelCode: "C.OrderConfirm.Address",
    LabelValue: "Giao đến địa chỉ",
  },

  {
    LabelCode: "C.OrderConfirm.City",
    LabelValue: "Thành phố",
  },

  {
    LabelCode: "C.OrderConfirm.Pricing",
    LabelValue: "Thanh toán",
  },

  {
    LabelCode: "C.OrderConfirm.TotalAmount",
    LabelValue: "Số tiền",
  },

  {
    LabelCode: "C.OrderConfirm.ShippingCharge",
    LabelValue: "Phí giao hàng",
  },
  {
    LabelCode: "C.OrderConfirm.GrandTotal",
    LabelValue: "Tổng cộng",
  },
  {
    LabelCode: "C.OrderConfirm.EditCheckout",
    LabelValue: "Cập nhật thông tin người nhận hàng",
  },
  {
    LabelCode: "C.OrderConfirm.SaveChanges",
    LabelValue: "Xác nhận",
  },
  {
    LabelCode: "C.OrderConfirm.Note",
    LabelValue: "Ghi chú",
  },
  {
    LabelCode: "C.OrderConfirm.CompleteOrder",
    LabelValue: "Xác nhận",
  },
  {
    LabelCode: "C.OrderConfirm.Title",
    LabelValue: "Xác nhận đơn hàng",
  },
  {
    LabelCode: "C.OrderCompleted.Title",
    LabelValue: "Đặt hàng thành công",
  },
  {
    LabelCode: "C.OrderConfirm.Title",
    LabelValue: "Complete Order",
  },
  {
    LabelCode: "C.OrderCompleted.Title",
    LabelValue: "Đặt hàng thành công",
  },
  {
    LabelCode: "C.OrderCompleted.OrderNumber",
    LabelValue: "Mã đơn hàng ",
  },
  {
    LabelCode: "C.OrderCompleted.Message",
    LabelValue: "Đơn hàng sẽ được giao tới bạn trong giây lát",
  },
  {
    LabelCode: "C.OrderCompleted.Continue",
    LabelValue: "Tiếp tục mua sắm",
  },
  {
    LabelCode: "C.OrderCompleted.OrderHistory",
    LabelValue: "Lịch sử mua hàng ",
  },
  {
    LabelCode: "P.Signin.SigninButton",
    LabelValue: "Gửi OTP ",
  },
  {
    LabelCode: "P.Signin.Header",
    LabelValue: "Đăng nhập",
  },
  {
    LabelCode: "P.Signin.Phone",
    LabelValue: "Số điện thoại",
  },
  {
    LabelCode: "P.OtpPage.Header",
    LabelValue: "Nhập mã OTP",
  },
  {
    LabelCode: "P.OtpPage.Verify",
    LabelValue: "Xác nhận",
  },
  {
    LabelCode: "P.OtpPage.TimeRemaining",
    LabelValue: "Thời gian còn lại ",
  },
  {
    LabelCode: "P.OtpExpired.Header",
    LabelValue: "OTP hết hạn",
  },
  {
    LabelCode: "P.OtpExpired.RenewOtp",
    LabelValue: "Gửi OTP mới",
  },
  {
    LabelCode: "P.OtpExpired.BackToHome",
    LabelValue: "Quay về trang chủ",
  },
  {
    LabelCode: "C.OrderConfirm.CreateOrder",
    LabelValue: "Đặt hàng",
  },
  {
    LabelCode: "C.HeaderBottom.ClickShowMap",
    LabelValue: "Click để xem chỉ đường",
  },
  {
    LabelCode: "P.CheckoutProcess.District",
    LabelValue: "Quận/Huyện",
  },
  {
    LabelCode: "P.CheckoutProcess.Ward",
    LabelValue: "Phường/Xã",
  },
  {
    LabelCode: "C.OrderHistory.OrderId",
    LabelValue: "Mã đơn hàng",
  },
  {
    LabelCode: "C.OrderHistory.Header.Total",
    LabelValue: "Số tiền",
  },
  {
    LabelCode: "C.OrderHistory.Header.OrderDate",
    LabelValue: "Ngày",
  },
  {
    LabelCode: "C.OrderHistory.Header.OrderStatus",
    LabelValue: "Trạng thái",
  },

  {
    LabelCode: "C.OrderHistory.Header.Detail",
    LabelValue: "Chi tiết",
  },
  {
    LabelCode: "C.OrderHistory.Header.Address",
    LabelValue: "Địa chỉ giao hàng",
  },
  {
    LabelCode: "C.OrderHistory.Header.Title",
    LabelValue: "Danh sách đơn hàng",
  },
  {
    LabelCode: "C.OrderDetail.Title",
    LabelValue: "Đơn hàng",
  },
  {
    LabelCode: "C.OrderDetail.OrderDate",
    LabelValue: "Ngày đặt hàng",
  },
  {
    LabelCode: "C.OrderDetail.InvoiceInformation",
    LabelValue: "Thông tin hóa đơn",
  },
  {
    LabelCode: "C.OrderDetail.InvoiceNumber",
    LabelValue: "Số hóa đơn",
  },
  {
    LabelCode: "C.OrderDetail.Url",
    LabelValue: "Truy cập",
  },
  {
    LabelCode: "C.OrderDetail.Secret",
    LabelValue: "Mã truy cập",
  },
  {
    LabelCode: "C.OrderDetail.OrderNumber",
    LabelValue: "Mã đơn hàng",
  },
  {
    LabelCode: "C.OrderDetail.OrderItems",
    LabelValue: "Danh sách sản phẩm",
  },
  {
    LabelCode: "C.OrderDetail.Unit",
    LabelValue: "ĐVT",
  },
  {
    LabelCode: "C.OrderDetail.RowTotal",
    LabelValue: "Tổng cộng",
  },
  {
    LabelCode: "C.OrderDetail.PaymentDetails",
    LabelValue: "Chi tiết thanh toán",
  },
  {
    LabelCode: "C.OrderDetail.TotalExVat",
    LabelValue: "Trước thuế",
  },
  {
    LabelCode: "C.OrderDetail.TotalVat",
    LabelValue: "Thuế VAT",
  },
  {
    LabelCode: "C.OrderDetail.DeliveryInfomation",
    LabelValue: "Thông tin giao hàng",
  },
  {
    LabelCode: "C.OrderDetail.GrandTotal",
    LabelValue: "Tổng cộng",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryDate",
    LabelValue: "Ngày giao hàng",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryFromTime",
    LabelValue: "Khoảng thời gian giao hàng",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryToTime",
    LabelValue: "Thời gian đến",
  },
  {
    LabelCode: "P.CheckoutProcess.ClientInfo",
    LabelValue: "Thông tin người nhận",
  },
  {
    LabelCode: "P.CheckoutProcess.AddressDetails",
    LabelValue: "Địa chỉ nhận hàng",
  },
  {
    LabelCode: "P.CheckoutProcess.DeliveryDateTime",
    LabelValue: "Thời gian giao hàng mong muốn",
  },
  {
    LabelCode: "C.OrderConfirm.DeliExpectation",
    LabelValue: "Thời gian giao hàng mong muốn",
  },
  {
    LabelCode: "P.Cart.Product",
    LabelValue: "Tên sản phẩm",
  },
  {
    LabelCode: "P.Cart.Header.Price",
    LabelValue: "Giá",
  },
  {
    LabelCode: "P.Cart.Header.Quantity",
    LabelValue: "Số lượng",
  },
  {
    LabelCode: "P.Cart.Header.Unit",
    LabelValue: "Đơn vị tính",
  },
  {
    LabelCode: "P.Cart.Header.SubTotal",
    LabelValue: "Tổng cộng",
  },
  {
    LabelCode: "C.HeaderBottom.Logout",
    LabelValue: "Đăng xuất",
  },
  {
    LabelCode: "P.EditProfile.Header",
    LabelValue: "Cập nhật thông tin cá nhân",
  },
  {
    LabelCode: "P.EditProfile.FullName",
    LabelValue: "Họ tên",
  },
  {
    LabelCode: "P.EditProfile.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "P.EditProfile.Phone",
    LabelValue: "Số điện thoại",
  },
  {
    LabelCode: "P.EditProfile.Address",
    LabelValue: "Địa chỉ",
  },
  {
    LabelCode: "P.EditProfile.City",
    LabelValue: "Tỉnh/Thành phố",
  },
  {
    LabelCode: "P.EditProfile.District",
    LabelValue: "Quận/Huyện",
  },
  {
    LabelCode: "P.EditProfile.Ward",
    LabelValue: "Phường/Xã",
  },
  {
    LabelCode: "C.HeaderBottom.Profile",
    LabelValue: "Hồ sơ của tôi",
  },
  {
    LabelCode: "C.HeaderBottom.Orders",
    LabelValue: "Đơn hàng",
  },
  {
    LabelCode: "P.Cart.CartTotal",
    LabelValue: "Tổng cộng",
  },
  {
    LabelCode: "P.Cart.Total",
    LabelValue: "Tiến hàng",
  },
  {
    LabelCode: "P.Cart.ProceedToCheckout",
    LabelValue: "Tiến hành đặt hàng",
  },
  {
    LabelCode: "C.OrderDetail.FullName",
    LabelValue: "Họ tên",
  },
  {
    LabelCode: "C.OrderDetail.Email",
    LabelValue: "Email",
  },
  {
    LabelCode: "C.OrderDetail.Note",
    LabelValue: "Ghi chú",
  },
  {
    LabelCode: "C.OrderDetail.Phone",
    LabelValue: "Điện thoại",
  },
  {
    LabelCode: "C.OrderDetail.Total",
    LabelValue: "Tiền hàng",
  },
  {
    LabelCode: "C.OrderDetail.ShippingFee",
    LabelValue: "Phí giao hàng",
  },
  {
    LabelCode: "C.OrderDetail.Address",
    LabelValue: "Địa chỉ nhận hàng",
  },

  {
    LabelCode: "C.OrderDetail.OrderStatus",
    LabelValue: "Trạng thái",
  },
  {
    LabelCode: "C.HeaderBottom.Price",
    LabelValue: "Giá",
  },
  {
    LabelCode: "C.AddressInput.Address",
    LabelValue: "Địa chỉ",
  },
  {
    LabelCode: "C.AddressInput.City",
    LabelValue: "Tỉnh/Thành",
  },
  {
    LabelCode: "C.AddressInput.District",
    LabelValue: "Quận/Huyện",
  },
  {
    LabelCode: "C.AddressInput.Ward",
    LabelValue: "Phường/Xã",
  },

  {
    LabelCode: "P.EditProfile.BirthDate",
    LabelValue: "Ngày sinh",
  },
  {
    LabelCode: "P.Card.Title",
    LabelValue: "Giỏ hàng của bạn",
  },
  {
    LabelCode: "P.EditProfile.BackToHome",
    LabelValue: "Về trang chủ",
  },
  {
    LabelCode: "P.EditProfile.Confirm",
    LabelValue: "Xác nhận",
  },
  {
    LabelCode: "C.Multi.BackToHome",
    LabelValue: "Về trang chủ",
  },
  //C.OrderHistory.OrderNo
  {
    LabelCode: "C.OrderHistory.OrderNo",
    LabelValue: "Mã giao hàng",
  },
  {
    LabelCode: "C.OrderHistory.FilterButton",
    LabelValue: "Lọc",
  },
  {
    LabelCode: "C.OrderHistory.OrderFromDate",
    LabelValue: "Đơn hàng từ ngày",
  },
  {
    LabelCode: "C.OrderHistory.OrderStatus",
    LabelValue: "Trạng thái đơn hàng",
  },
  {
    LabelCode: "P.Cart.P1",
    LabelValue: "Không còn sản phẩm trong giở hàng",
  },
  {
    LabelCode: "P.Cart.P2",
    LabelValue:
      "Rất nhiều sản phẩm đang chờ để bạn có thể lấp đầy giỏ hàng của mình",
  },
  {
    LabelCode: "P.Cart.ContinueShopping",
    LabelValue: "Tiếp tục mua sắm",
  },
  {
    LabelCode: "C.HeaderBottom.Login",
    LabelValue: "Đăng nhập",
  },
  {
    LabelCode: "C.HeaderBottom.SignUp",
    LabelValue: "Đăng ký",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyName",
    LabelValue: "Vui lòng nhập họ tên",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyEmail",
    LabelValue: "Vui lòng nhập email",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyPhone",
    LabelValue: "Vui lòng nhập số điện thoại",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyAddress",
    LabelValue: "Vui lòng nhập số địa chỉ",
  },
  {
    LabelCode: "Error.CheckOutProcess.InvalidDeliveryDate",
    LabelValue: "Thời gian nhận hàng phải sau thời điểm hiện tại",
  },
  {
    LabelCode: "Error.CheckOutProcess.InvalidDeliveryFromTime",
    LabelValue: "Thời gian nhận hàng phải sau thời điểm hiện tại",
  },
  {
    LabelCode: "Error.CheckOutProcess.EmptyPeriod",
    LabelValue: "Phải chọn thời gian nhận hàng",
  },
  {
    LabelCode: "Error.CheckOutProcess.ToastMsg",
    LabelValue: "Vui lòng nhập đúng và đủ các thông tin được yêu cầu",
  },
  {
    LabelCode: "Error.Phone.Format",
    LabelValue: "Số điện thoại sai định dạng",
  },
  {
    LabelCode: "Error.Phone.Empty",
    LabelValue: "Bạn phải nhập số điện thoại",
  },
  {
    LabelCode: "P.EditProfile.FullName.Plh",
    LabelValue: "Nguyen Van An",
  },
  {
    LabelCode: "P.EditProfile.Email.Plh",
    LabelValue: "example@email.com",
  },
  {
    LabelCode: "P.CheckOutProcess.SlotInputPlh",
    LabelValue: "Chọn thời gian nhận hàng",
  },
  {
    LabelCode: "C.OrderDetail.PaidAmount",
    LabelValue: "Đã thanh toán",
  },
  {
    LabelCode: "C.Multi.PayLater",
    LabelValue: "Thanh toán bằng tiền mặt",
  },
  {
    LabelCode: "C.Multi.PayNow",
    LabelValue: "Ví MoMo hoặc chuyển khoản",
  },
  {
    LabelCode: "C.Multi.Pay",
    LabelValue: "Thanh toán ",
  },
  {
    LabelCode: "Error.Capcha",
    LabelValue: "Vui lòng nhập vào Captcha",
  },
  {
    LabelCode: "C.Multi.Voucher",
    LabelValue: "Voucher"
  },
  {
    LabelCode: "C.PaymentMethodSelector.Select",
    LabelValue: "Chọn phương thức thanh toán"
  },
  {
    LabelCode: "C.OrderDetail.Remain",
    LabelValue: "Cần thanh toán"
  }
];
