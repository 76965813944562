import BackendApi from "../common.js/backendApi";
import BizError from "../common.js/BizError";
import { CONST_DATA } from "../common.js/constData";
import {  fetchDataNoContent } from "../common.js/http";
import { DateToInt, DateToString } from "../utils/DatetimeHelper";
import { GetOrderById, InitPatment } from "./OrderServices";
import { store } from "../redux/store";
async function CheckPaymentResult(params) {
    
    let url = `${BackendApi.CheckPaymentResult.url}`;
    let props = {
        url: url,
        method: BackendApi.CheckPaymentResult.method,
        body: params,
    };
    var rs= await fetchDataNoContent(props);
    console.log(rs);
    return rs;
}
async function CreatePaymentByOrderId(order_id, serviceCode) {
    let orderResult =await GetOrderById(order_id);
    if (orderResult.success) {
        let order  =  orderResult.results[0];
        var createPaymentResponse = CreatePaymentByOrder(order, serviceCode);
        return createPaymentResponse;
     
    }
    else {
        throw new BizError("Không thể lấy thông tin đơn hàng");
    }
}
async function CreatePaymentByOrder(order, serviceCode) {
  let initPaymentRessult = await InitPaymentCms( order);
  if(!initPaymentRessult.success){
    throw new BizError("Lỗi không thể khởi tạo thanh toán");
  }
  let paymentReq = BuildPayment(order, initPaymentRessult.results[0], serviceCode);
  let response = await CreatePayment(paymentReq);
  return response;
}
const CreatePayment = async (paymentReq) => {
  try {
    const response = await fetch(BackendApi.CreatePayment.url, {
      method: BackendApi.CreatePayment.method,
      headers: createHeaders(),
      body: JSON.stringify(paymentReq),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw new BizError("Lỗi khi khởi tạo giao dịch thanh toán với đối tác");
  }
};
function createHeaders() {
  const headers = {
    "Accept-Language": "vn",
    "Content-Type": "application/json",
  };
  return headers;
}

async function InitPaymentCms( order) {
    let params ={
        customer_no: order.customer_no,
        order_id: order.order_id, 
        RequestPaymentAmount: CalcPayAmount(order).RequiredPayAmount
      }
      let  response  =await InitPatment(params);
      return response;
}
const CalcPayAmount = (order)=>{
  const PaidAmount = order.payment_list.reduce((sum, payment) => sum + payment.ActualAmount, 0);
  const TotalAmount = order.total_price_ex_vat+ order.total_vat;
  const RequiredPayAmount = TotalAmount-PaidAmount;
  return {
    RequiredPayAmount : RequiredPayAmount, 
    TotalAmount : TotalAmount
  };
}

const BuildPayment = (order,initRs, serviceCode)=>{
    
    // const PaidAmount = order.payment_list.reduce((sum, payment) => sum + payment.ActualAmount, 0);
    // const TotalAmount = order.total_price_ex_vat+ order.total_vat;
    // const RequiredPayAmount = TotalAmount-PaidAmount;
    // console.log(order);
    let payAmtInfo = CalcPayAmount(order);
    let orderItems  =order.order_items.map((item)=>{
      return {
        ProductCode:item.Product.product_code,
        ProductName: item.Product.short_desc_local,
        Quantity: item.order_qty,
        PriceInvat:item.total_price_in_vat,
        PriceExVat: item.total_price_ex_vat,
        TotalPrice: item.order_qty*item.total_price_in_vat
      }
    })
    const state = store.getState();
    const customerId = state.FoxReducer.userInfo.customers[0].customer_id;
    let payementReq = {
      MerchantCode: CONST_DATA.PAYMENT.MERCHANT_CODE,
      MerchantTransId: initRs.payment_id,
      ServiceCode:serviceCode?serviceCode:CONST_DATA.PAYMENT.SERVICE_CODE,
      PaymentAmount:payAmtInfo.TotalAmount,
      // order.total_price_ex_vat+order.total_vat,
      TransDate:DateToInt(new Date()),
      ReturnUrl: `${window.location.origin}/payment-result`,
      IPNUrl: initRs.ipnUrl,
      PaymentMethodList: ["DECDMOON"],
      Signature : initRs.signature,
      Order: {
        OrderId: order.order_id.toString(),
       
        OrderStoreId : order.store_code,
        OrderStoreName: order.store_code,
        OrderTotalAmount: payAmtInfo.TotalAmount,
        OrderPaymentAmount: payAmtInfo.RequiredPayAmount,
        //payAmtInfo.RequiredPayAmount,
        OrderDate: DateToString(new Date()),// YYYY-MM-DD
        OrderItems: orderItems
        
      },
      Customer : {
        CustomerId: customerId,
        CustomerNo: order.customer_no,
        CustomerName: order.customer_name, 
        CardNo: order.card_no,
        CustomerType: "B2C",
     }
    }
    return payementReq;
  }
export {CheckPaymentResult, CreatePaymentByOrderId, CreatePaymentByOrder}