import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  genToken,
  getCustomerByToken,
  loginBySession,
  LogoutApp,
} from "../../services/AccountServices";
import {
  addListToWishList,
  clearAllWishList,
  createOrUpdateCart,
  createOrUpdateDummy,
  logout,
  resetCart,
  updateCartItemsFromSever,
  updateOrderDelivery,
  updateUserInfo,
  updateUserToken,
} from "../../redux/FoxSlice";
import { useDispatch } from "react-redux";
import { CONST_DATA } from "../../common.js/constData";
import {
  CreateCart,
  GetNewOwnerCart,
  MergeCartDummnyToIdentityCustomer,
} from "../../services/CartServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { FoxProduct } from "../../common.js/transformation";
import { getWishList, updateWishList } from "../../services/WishListServices";
import { CreateCartAnyway } from "../../services/CartServices";
import { toast } from "react-toastify";
const LoginSession = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const segments = location.pathname.split("/");
  const [searchParams] = useSearchParams();
  let targetUrl = searchParams.get("target-url");
  targetUrl=formatTargetUrl(targetUrl);
  let sesionId;
  if (segments.length > 0) {
    sesionId = segments[2];
  } else sesionId = "";
  function formatTargetUrl(targetUrl) {
    const unexpectedValues = ["/", "null", "undefined", "#", "*"];

    if (!targetUrl) {
        return "home";
    }
    
    if (unexpectedValues.includes(targetUrl)) { // Thay Contains bằng includes
        return "home";
    }

    return targetUrl;
}

  function updateLocalCartItems(data) {
    let cartItems = data.results[0].CartItems;

    let products = cartItems.map((item) => {
      return FoxProduct(item);
    });
    return products;
  }
  const GetWishList = async () => {
    let wl_list_Resonse = await getWishList(1, 100);

    if (wl_list_Resonse.success) {
      var p_list_custom = [];
      if (wl_list_Resonse.results != null && wl_list_Resonse.results.length) {
        wl_list_Resonse.results.forEach((item) => {
          p_list_custom.push({ product_code: item.product_code });
        });
        dispatch(clearAllWishList());
        dispatch(addListToWishList(p_list_custom));
      } else dispatch(clearAllWishList());
    }
  };
  const UpdateWishList = async (new_cusstomer_no) => {
    let data = {
      new_customer_no: new_cusstomer_no,
      channel_code: CONST_DATA.CHANNEL,
    };
    let wlResonse = await updateWishList(data);
  };
  useEffect(() => {
    const initDummyData = async () => {
      try {
        const loginResonse = await loginBySession(sesionId);
        if (loginResonse.success) {
          const userToken = {
            mm_token: loginResonse.result.token,
            refreshToken: loginResonse.result.refreshToken,
          };
          dispatch(updateUserToken(userToken));
          const cust_no = loginResonse.result.customer_no;

          let rs = await CreateCartAnyway(cust_no, loginResonse.result.token);
          if (rs == null) {
            toast.error("Can't create cart");
          }

          let cartResponse = await GetNewOwnerCart(
            cust_no,
            CONST_DATA.STORE_DEFAULT
          );

          if (cartResponse.success) {
            if (cartResponse.results.length > 0) {
              let products = updateLocalCartItems(cartResponse);

              dispatch(updateCartItemsFromSever(products));
              let payload = {
                cart_id: cartResponse.results[0].id,
                customer_no: cust_no,
              };
              dispatch(createOrUpdateCart(payload));
            } else dispatch(createOrUpdateCart(null));
          } else dispatch(createOrUpdateCart(null));

          let getCusResponse = await getCustomerByToken();

          dispatch(updateUserInfo(getCusResponse.result));
          GetWishList();
          navigate("/" + targetUrl);
        } else {
          navigate("/signin");
        }
        //navigate(returnUrl);
      } catch (error) {
        console.log(error);
      }
    };
    initDummyData();
  }, [dispatch]);
  return <>logout....</>;
};
export { LoginSession };
