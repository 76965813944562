import React, { useEffect, useState } from 'react';
import { CheckPaymentResult } from '../../services/PaymentServices';
import { useNavigate } from 'react-router-dom';
import { GetOrderById } from '../../services/OrderServices';
import { IoReorderTwoOutline } from 'react-icons/io5';

const PaymentResult = () => {
  const [params, setParams] = useState({});
  const navigate = useNavigate();
  const IsPaymentCompleted = async (paymentResult, id) => {
    try{
      let response = await GetOrderById(id);
      const totalActualAmount = response.results[0].payment_list.reduce(
        (sum, payment) => sum + payment.ActualAmount,
        0
      );
      let totalAmount =response.results[0].total_price_ex_vat+response.results[0].total_vat;
      if(totalActualAmount<totalAmount){
         //return `/order-payment/${paymentResult.OrderId}`
         return false;
      }
      else {
        return true;
      }
       // Trả về giá trị nếu cần
    }
    catch(ex){
        return `/order-payment/${paymentResult.OrderId}`
    }
    
  };
  
  useEffect(() => {

    const checkPayment = async (paymentResult) => {
      var rs =await CheckPaymentResult(paymentResult);
      var PaymentCompleted =await IsPaymentCompleted(paymentResult, paymentResult.OrderId);

      if(PaymentCompleted){
        
          navigate("/order-completed", {
            state: { order_no: paymentResult.OrderId},
          });
         
      }
      else {
        navigate(`/order-payment/${paymentResult.OrderId}`);
      }
    }
    // Create a URLSearchParams object from the query string in the URL
    const queryParams = new URLSearchParams(window.location.search);

    // Create an object to store the parameters
    const paramsObject = {};
    
    // Iterate over all the query parameters
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    // Set the params state with all the query parameters
    setParams(paramsObject);
    checkPayment(paramsObject);
    console.log(paramsObject);
  }, []);


  return (
    <div className="max-w-container mx-auto px-0 pt-5 md:pt-2">
        <div className="mt-20"></div>
      <div className="border-b pb-4 mb-6 md:mt-100 ">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Đã nhận kết quả thanh toán
          </h2>
        </div>
      </div>
    </div>
  );
};
export default PaymentResult