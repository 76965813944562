import { useState, useEffect } from "react";
import { FaTimes, FaMoneyBill } from "react-icons/fa";
import { FaGift } from "react-icons/fa";
import MomoIcon from "./MomoIcon";
import { LableItem } from "../../LabelLanguge/LabelItem";

const PaymentMethodSelector = ({handlePayNow, handlePayLater, handlePaymentVoucher}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === "Escape") setIsPopupOpen(false);
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, []);
  const paymentHandlers = {
    cash: handlePayLater,
    momo: handlePayNow,
    voucher: handlePaymentVoucher
  };
  
  
  
  const handlePayment = async (method) => {
    setIsLoading(true);
    setError(null);
    try {
      setSelectedMethod(method);
      
      if (paymentHandlers[method]) {
        await paymentHandlers[method]();
      }
      setIsPopupOpen(false);
    } catch (err) {
        console.log(err);
      setError("Payment processing failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const paymentMethods = [
    {
      id: "cash",
      name: "Cash Payment",
      icon: <FaMoneyBill className="text-2xl" />,
      description: <LableItem code="C.Multi.PayLater"></LableItem>
    },
    {
      id: "momo",
      name: "Mobile Money",
      icon: <MomoIcon></MomoIcon>,
    //   <FaMobile className="text-2xl" />,
      description:  <LableItem code="C.Multi.PayNow" />
    },
    {
      id: "voucher",
      name: "Voucher",
      icon: <FaGift className="text-2xl" />,
      description: <LableItem code="C.Multi.Voucher"></LableItem>
    }
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsPopupOpen(true)}
        className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        aria-label="Select Payment Method"
      >
        <LableItem code="C.PaymentMethodSelector.Select"></LableItem>
      </button>

      {isPopupOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={() => setIsPopupOpen(false)}
            aria-label="Close popup"
          />
          <div className="relative bg-white rounded-xl shadow-2xl p-6 w-full max-w-md m-4 transform transition-all scale-100 opacity-100">
            <button
              onClick={() => setIsPopupOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Close dialog"
            >
              <FaTimes className="text-xl" />
            </button>

            <h2 className="text-2xl font-bold mb-6 text-gray-800"><LableItem code="C.PaymentMethodSelector.Select"></LableItem></h2>

            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            <div className="space-y-4">
              {paymentMethods.map((method) => (
                <button
                  key={method.id}
                  onClick={() => handlePayment(method.id)}
                  disabled={isLoading}
                  className={`w-full p-4 flex items-center justify-between rounded-lg border-2 transition-all duration-200 ${
                    isLoading && selectedMethod === method.id
                      ? "bg-gray-100 cursor-not-allowed"
                      : "hover:border-blue-500 hover:bg-blue-50"
                  }`}
                  aria-label={`Pay with ${method.name}`}
                >
                  <div className="flex items-center space-x-4">
                    <div className="text-blue-600">{method.icon}</div>
                    <div className="text-left">
                      <h3 className="font-semibold text-gray-800">{method.name}</h3>
                      <p className="text-sm text-gray-600">{method.description}</p>
                    </div>
                  </div>
                  {isLoading && selectedMethod === method.id && (
                    <div className="animate-spin rounded-full h-5 w-5 border-2 border-blue-600 border-t-transparent" />
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodSelector;